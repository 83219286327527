<template>
  <div class="change-password">
    <header class="page-header">
      <van-icon name="arrow-left" @click="$router.go(-1)" />
      <span>{{ $t('ChangePwd.修改密码') }}</span>
      <span></span>
    </header>
    <main class="page-body">
      <div class="label">{{ $t('ChangePwd.旧密码') }}</div>
      <password-field
        v-model="oldPassword"
        clearable
        :placeholder="$t('ChangePwd.请输入旧密码')"
      ></password-field>
      <div class="label">{{ $t('ChangePwd.新密码') }}</div>
      <password-field
        v-model="newPassword"
        clearable
        :placeholder="$t('ChangePwd.请输入新密码')"
      ></password-field>
      <div class="label">{{ $t('ChangePwd.确认新密码') }}</div>
      <password-field
        v-model="passwordAgain"
        clearable
        :placeholder="$t('ChangePwd.请再次输入新密码')"
      ></password-field>
      <van-button
        class="submit"
        type="info"
        @click="onSubmit"
        :disabled="!valid"
      >
        {{ $t('ChangePwd.确定') }}
      </van-button>
    </main>
  </div>
</template>
<script>
import { changePassword } from '@/api/account.js';
export default {
  data() {
    return {
      oldPassword: '',
      newPassword: '',
      passwordAgain: '',
    };
  },
  computed: {
    valid() {
      return (
        this.oldPassword &&
        this.newPassword &&
        this.passwordAgain &&
        this.newPassword === this.passwordAgain
      );
    },
  },
  methods: {
    onSubmit() {
      changePassword({
        oldPwd: this.oldPassword,
        newPwd: this.newPassword,
      }).then(res => {
        if (res.Result) {
          this.$router.go(-1);
        } else {
          this.$toast.fail(res.Info);
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
@import '~@/styles/_var';
.page-header {
  font-size: 21px;
}
.change-password {
  background-color: #fff;
}
.page-body {
  padding: rem(60px);
}
.label {
  font-size: 16px;
  margin-bottom: rem(30px);
}
.form-field {
  margin-bottom: rem(40px);
}
.van-field {
  border-radius: rem(25px);
  background-color: #f7f7fc;
  font-size: 16px;
  padding-top: rem(24px);
  padding-bottom: rem(24px);
  margin-bottom: rem(40px);
}
.submit {
  height: rem(100px);
  line-height: rem(100px);
  width: 100%;
  border-radius: rem(50px);
  margin-top: rem(156px);
  font-size: 16px;
}
</style>
